let frontendAuth = true

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import SuperAdmin from './superAdmin'
import Customers from "./customers";
import Locations from "./locations";
import UserTypes from "./userTypes";
import Users from "./users";
import PurchaseOrders from "./purchaseOrders";
import Suppiers from "./suppliers";
import Orders from "./orders";
import OrderTypes from "./orderTypes";
import PaymentTypes from "./paymentTypes";
import ProductAttributes from "./productAttributes";
import ProductAttributeTerms from "./productAttributeTerms";
import ProductTags from "./productTags";
import ProductCategories from "./productCategories";
import Products from "./products";
import Brand from "./brands";
import Imager from "./imager";
import Inventory from "./inventory";
import ReceivingReport from "./receivingReports";
import Delivery from "./deliveries";
import Returns from "./returns";
import Reporting from "./reporting";

Vue.use(VueRouter)

let routes = []
let defaultRoutes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },  
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue'),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem('id')) next()
      else next({name: from.name})
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "Logout" */ '../views/Logout.vue'),
    // beforeEnter: (to, from, next) => {
    //   if (!localStorage.getItem('id')) next()
    //   else next({name: from.name})
    // }
  }
]

routes = routes.concat(
    defaultRoutes,
    SuperAdmin,
    Customers,
    Locations,
    UserTypes,
    Users,
    Suppiers,
    OrderTypes,
    PaymentTypes,
    ProductAttributes,
    ProductAttributeTerms,
    ProductTags,
    ProductCategories,
    Products,
    PurchaseOrders,
    Brand,
    Imager,
    Inventory,
    Orders,
    ReceivingReport,
    Delivery,
    Returns,
    Reporting
);

const router = new VueRouter({
  routes
})

if(frontendAuth){
  router.beforeEach((to, from, next) => {
    if (!localStorage.getItem('id') && to.path !== '/login') next({ name: 'Login' })
    else next()
  })
}


export default router
