import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    scanTypes: ["VSID"],
    id: null,
    user: null,
    allowedBranches: [],
    GLOBALS: [],
    perms: null,
    endpoint: null,
    workingId: null,
    globalQueryBc: true,
    userCache: [],
    branches: [],
    scanBus: {
      data: null,
      handler: 'global',
    },
    isManaged: null,
    locations: [],
    paymentTypes: [],
    paymentMethods: [],
    menuItems: [
      { icon: 'mdi-magnify', text: 'Home', path: '/', protected: false, filterIdentifier: 'SEARCH'},
      { icon: 'mdi-camera', text: 'Product Manager', path: '/imager', protected: false, filterIdentifier: 'IMAGER'},
      { icon: 'mdi-plus-circle', text: 'Product Manager', path: '/products/create', protected: false, filterIdentifier: 'CREATOR'},
      { icon: 'mdi-barcode-scan', text: 'Product Manager', path: '/inventory', protected: false, filterIdentifier: 'STOCKTAKER'},
      { icon: 'mdi-close-octagon', text: 'Super Admin', path: '/superadmin', protected: false, filterIdentifier: 'SUPER'},
      
    ],
    activateScanSensePing: false,
    envConstants: null,
  },
  mutations: {
    setUser(state, user){
      localStorage.setItem('id', user.id)
      state.user = user
      state.id = user.id
      state.allowedBranches = state.user.metadata.branchData.allowed.map(x => {
        return state.branches.find(n => x===n.id);
      });
    },
    setUserCache(state, users){
      state.userCache = users
      console.log(state.userCache.length > 0 ? "VUEX: ✅ User Cache loaded." : "VUEX: ❌ User Cache not loaded.")
    },
    setBranches(state, branches){
      state.branches = branches
      console.log(state.branches ? "VUEX: ✅ Branches loaded." : "VUEX: ❌ Branches not loaded.")
    },
    setLocations(state, locations){
      state.locations = locations
      console.log(state.locations ? "VUEX: ✅ Locations loaded." : "VUEX: ❌ Locations not loaded.")
    },
    setPaymentMethods(state, paymentMethods){
      state.paymentMethods = paymentMethods
      console.log(state.paymentMethods.length > 0 ? "VUEX: ✅ Payment Methods Cache loaded." : "VUEX: ❌ Payment Methods Cache not loaded.")
    },
    setPaymentTypes(state, paymentTypes){
      state.paymentTypes = paymentTypes
      console.log(state.paymentTypes.length > 0 ? "VUEX: ✅ Payment Types Cache loaded." : "VUEX: ❌ Payment Types Cache not loaded.")
    },
    setBranch(state, branch){
      state.branch = branch
    },
    clearUserCache(state){
      state.userCache = []
    },
    setPerms(state, perms){
      state.perms = perms;
    },
    setEndpoint(state, endpoint){
      state.endpoint = endpoint;
    },
    updateWorkingId(state, wId){
      localStorage.setItem('workingId', wId)
      state.workingId = wId;
    },
    deleteWorkingId(state){
      localStorage.removeItem('workingId')
      state.workingId = null;
    },
    lockGlobalQueryBc(state){
      state.globalQueryBc = false
      console.log("Global Barcode Listener Locked")
    },
    unlockGlobalQueryBc(state){
      state.globalQueryBc = true
      console.log("Global Barcode Listener Unlocked")
    },
    initialiseStore(state) {
      if (localStorage.getItem('id')) {
        state.id = localStorage.getItem('id');
        state.workingId = localStorage.getItem('workingId');
      }
    },
    logout(state){
      localStorage.removeItem('id')
      localStorage.setItem('workingId', null)
      state.id = null;
      state.workingId = null;
      state.perms = null;
      state.user = null
    },
    removeIds(state){
      state.id = null;
      state.userName = null;
      state.user = null
    },
    setScanBus(state, bus=null){ //{handler: null, data:null}
      // if(bus.data){
      //   state.scanBus.data = bus.data
      // }
      state.scanBus.data = bus.data
      if(bus.handler){
        state.scanBus.handler = bus.handler
      }
    },
    resetScanBus(state){
      state.scanBus.handler = 'global'
      state.scanBus.data = null
    },
    setManaged(state, val){
      state.isManaged = val;
    },
    setGLOBALS(state, val){
      state.GLOBALS = val;
      console.log("VUEX: ✅ DB Globals Set.")
    },
    setEnvConstants(state, val){
      state.envConstants = val;
    },
    activateScanSense(state){
      state.activateScanSensePing = true
    },
  },
  actions: {    
  },
  modules: {
  },
  getters: {
    getId: state => state.id,
    getEndpoint: state => state.endpoint,
    getWorkingId: state => state.workingId,
    getPerms: state => state.perms,
    getUserCache: state => state.userCache,
    lookupUsername: (state) => (id) =>{
      if(!id) return null
      let y = state.userCache.find(x=>x.id==id)
      if(!y) return null
      return y.firstName + ' ' + (y.lastName?y.lastName:'')
    },
    getBranch: state => state.user?.metadata?.branchData?.main,
    getBranches: state => state.branches,
    lookupLocation: (state) => (id) =>{
      if(!id) return null
      let y = state.locations.find(x=>x.id==id)
      if(!y) return null

      let nameStr = '';
      let curr = y;
      while(curr.parent!==null){
        nameStr='>'+curr.name+nameStr;
        curr = state.locations.find(x=>x.id==curr.parent)
      }
      nameStr=curr.name+nameStr;
      return nameStr;
    },
    getAllowedBranches: state => state.allowedBranches,
    lookupBranch: (state) => (id) =>{
      if(!id) return null
      let y = state.branches.find(x=>x.id==id)
      if(!y) return null
      return y.name;
    },
    lookupPaymentType: (state) => (id) =>{
      if(!id) return null
      let y = state.paymentTypes.find(x=>x.id==id)
      if(!y) return null
      return y.name;
    },
    lookupPaymentMethod: (state) => (id) =>{
      if(!id) return null
      let y = state.paymentMethods.find(x=>x.id==id)
      if(!y) return null
      return y.name;
    },
    getBranchData: (state) => (id) =>{
      if(!id) return null
      let y = state.branches.find(x=>x.id==id)
      if(!y) return null
      return y;
    },
    getUser: state => state.user,
    isLoggedIn: state => state.id ? true : false,
    globalQueryBcAllowed: (state)=>{
      return state.globalQueryBc
    },
    isAllowed: (state) => (resource, requiredPerm) => {
      if(!resource) return false
      if(!requiredPerm) return false
      if(!state.perms) return false
      return (state.perms.permissions[resource])[requiredPerm]
    },
    getRole: state => state.perms?.description,
    nowServing: state => state.sIDAuthorizer.nowServing,
    scanBus: state => state.scanBus,
    isManaged: state => state.isManaged,
    menuItems: (state) => {
      let allowedMenuItems = state.GLOBALS.find(x=>x.key=="VEC_INVENTORY_ASSISTANT_MENU_ITEMS")?.value.split("\n")
      if(!allowedMenuItems) return
      //console.log(allowedMenuItems, state.menuItems)
      let x = state.menuItems.filter(x=>(allowedMenuItems.find(y=>y==x.filterIdentifier)!=undefined)&&state?.perms?.permissions.allowedMenuItems[x.filterIdentifier]==true)
      return x
    },
    paymentMethods: state => state.paymentMethods,
    paymentTypes: state => state.paymentTypes,
    activateScanSensePing: state => state.activateScanSensePing,
    getGlobalValue: (state) => (key) => (state.GLOBALS.find(x=>x.key==key))?.value,
    envConstants: state => state.envConstants,
  }
})
