<template>
  <v-app id="inspire" style="background-color: rgba(0,0,0,0.08);">
    <span v-if="!this.ready" style="height: 100%;" class="d-flex flex-row align-center justify-center">
      <v-progress-circular
          indeterminate
          color="green"
      ></v-progress-circular>
    </span>
    <span v-else>
      <v-main>
        <div v-if="this.isLoggedIn && this.getUser" :style="'width: 100%; height: '+(this.getGlobalValue('VEC_ADMIN_TOPNAV_HEIGHT')||'60px')+'; background-color: '+(this.getGlobalValue('VEC_ADMIN_TOPNAV_BG_COLOR')||'#2970C0')+'; color:'+(this.getGlobalValue('VEC_ADMIN_TOPNAV_FONT_COLOR')||'white!important')+';padding: 10px'" class="d-flex flex-row justify-space-between">
          <span class="d-flex flex-column align-start justify-center">
            <img class="ml-3" :src="this.getEndpoint+'/images/logo.png'" :height="(this.getGlobalValue('VEC_ADMIN_TOPNAV_LOGO_HEIGHT')||'50px')" alt="">
            <span style="font-size: 9px;">{{getGlobalValue('ADMIN_APPLICATION_NAME')}}</span>
          </span>
          <span class="d-flex flex-row align-center">
            <span v-if="isAllowed('user', 'changeMain')&&(this.getGlobalValue('VEC_INCLUDES_MULTIBRANCH')=='true')" class="d-flex flex-row">
              <v-select
                  class="ignoreWhite"
                  outlined
                  dense
                  dark
                  style="margin-bottom: -20px; width: 200px;"
                  label="Current Branch"
                  :items="this.getAllowedBranches"
                  item-text="name"
                  item-value="id"
                  :value="this.getBranch"
                  @change="updateBranch($event)"
                  :loading="branchLoader"
              />
            </span>
            <span v-else>
              <span v-if="(this.getGlobalValue('VEC_INCLUDES_MULTIBRANCH')=='true')">Branch: {{this.lookupBranch(this.getUser.metadata.branchData.main)}}</span>
            </span>
            <span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="ml-1" :color="isManaged?'white':'#bbb'" v-bind="attrs" v-on="on">mdi-sitemap</v-icon>
                </template>
                <span>This branch uses {{isManaged?'a ':'an un'}}managed inventory system.</span>
              </v-tooltip>
            </span>
            <v-btn class="ml-3" @click="logoutA" color="success" x-small fab><v-icon>mdi-close</v-icon></v-btn>
          </span>
        </div>
        <v-container
          class="fill-height"
          style="justify-content: flex-start; align-items: flex-start; padding: 30px 30px 30px 30px; position: relative; z-index:0;"
          v-if="(this.isLoggedIn && this.getUser) || (!this.isLoggedIn && !this.getUser)"
          fluid
        >
          <router-view :key="$route.path+this.getBranch"/>
        </v-container>
        <v-dialog v-model="logoutDialog" width="500">
          <v-card>
            <v-card-title>Confirm Logout</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="logoutB()"
              >
                Logout
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="logoutDialog = false"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="productDialog.isOpen" width="500">
          <v-card>
            <v-card-title>Scanned Product</v-card-title>
            <v-card-text>
              <v-progress-circular
                indeterminate
                color="green"
                v-if="productDialog.isLoading"
                style="margin-left: 10px;"
              ></v-progress-circular>
              <div class="d-flex flex-row justify-space-between" style="align-text: left; background-color: rgba(0,0,0,0.05); padding: 10px; margin-top: 6px; border-radius: 7px;">
                <div class="d-flex flex-column justify-space-between">
                  <span class="d-flex flex-row justify-space-between">
                    <b style="text-align: left;">{{productDialog.data.Brand?`${productDialog.data.Brand.name} `:''}} {{productDialog.data.name}}</b>
                  </span>
                  <span class="d-flex flex-column align-start">
                    <span><b>SP: ${{productDialog.data.regularPrice}}</b>, <span v-if="isAllowed('product', 'viewCostPrice')">PP: ${{productDialog.data.salePrice}}</span></span>
                    <span>ID: {{productDialog.data.id}}, SKU:{{productDialog.data.sku}}</span>
                  </span>
                </div>
                <v-btn fab color="success" @click="goToProduct(productDialog.data)" x-small><v-icon>mdi-chevron-right</v-icon></v-btn>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="closeProductDialog"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-bottom-navigation
          color="blue"
          fixed
          grow
          style="width: 100%; z-index:0"
        >
          <v-btn v-for="(item, index) of menuItems" :key="index" :to="item.path">
            <v-icon>{{item.icon}}</v-icon>
          </v-btn>
        </v-bottom-navigation>
      </v-main>
      <v-snackbar style="margin-bottom: 60px;" v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
        {{ snackObj.text }}
        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
        </template>
      </v-snackbar>
    </span>
  </v-app>
</template>

<style>
  .v-text-field:not(.ignoreWhite)>.v-input__control>.v-input__slot fieldset{
    background-color: white;
  }
</style>

<script>
  import { mapGetters, mapMutations } from "vuex"
  import scanSystem from "./plugins/scanSystem"
  import axios from 'axios'
  import utils from "./plugins/helpers"
  export default {
    props: {
      source: String,
    },
    data: () => ({
      utils: utils,
      ready: false,
      dialog: false,
      drawer: null,
      timeout: null,
      logoutDialog:false,

      search: {
        value: "",
        type: "Products",
        types: ["Products", "Customers"],
        dialog: false,
        loading: false,
        results: [],
      },

      snackObj: {
        state: false,
        color: '',
        text: ''
      },
      branchLoader: false,
      chordingDialog:{
        isOpen: false,
        chord: []
      },
      productDialog: {
        isOpen: false,
        isLoading: false,
        data: {},
      }
    }),
    watch: {
      $route (to){
        if(to.name==="Home"){
          this.resetScanBus()
        }
        if(to.name==="order"){
          this.setScanBus({handler: 'order'})
        }
        // if(from.name==="order"&& to.name!=="order"){
        //   this.resetScanBus()
        // }
        if(to.name==="Return"){
          this.setScanBus({handler: "return"})
        }
        // if(from.name==="Return"&& to.name!=="Return"){
        //   this.resetScanBus()
        // }
        if(to.name==="Imager"){
          this.setScanBus({handler: "imager"})
        }
        // if(from.name==="Imager"&& to.name!=="Imager"){
        //   this.resetScanBus()
        // }
        if(to.name==="Inventory"){
          this.setScanBus({handler: "inventory"})
        }
        // if(from.name==="Inventory"&& to.name!=="Inventory"){
        //   this.resetScanBus()
        // }
        if(to.name==="Create Product"){
          this.setScanBus({handler: "product"})
        }
        // if(from.name==="Create Product"&& to.name!=="Create Product"){
        //   this.resetScanBus()
        // }
      },
      activateScanSensePing(){
        window.removeEventListener('keydown', this.keydown)
        // this.loadProductCache()
        this.activateScanSense()
      },
      scanBus: {
        handler(){
          console.log("APP.VUE Watcher: ScanBus Changed")
          if(!this.scanBus.data || this.scanBus.data == "" || this.scanBus.data == undefined){
            console.log("Watcher detected that scanbus was reset. Terminating handoff to handler.")
            return
          }
          if(this.scanBus.handler == 'global'){
            this.globalHandler()
          }
        },
        deep: true
      }
    },
    async mounted(){
      try {
        // very important: determine what is the origin
        let origin = window.location.origin;
        if(origin == process.env.VUE_APP_CLOUD_ORIGIN){
          this.setEndpoint(process.env.VUE_APP_CLOUD_ENDPOINT)
        }else if(origin == process.env.VUE_APP_PREM_ORIGIN){
          this.setEndpoint(process.env.VUE_APP_PREM_ENDPOINT)
        }else if(origin.includes(process.env.VUE_APP_LOCALHOST_ORIGIN)){
          this.setEndpoint(process.env.VUE_APP_LOCALHOST_ENDPOINT)
        }else if(origin.includes(process.env.VUE_APP_PUBLIC_ORIGIN)){
          this.setEndpoint(process.env.VUE_APP_PUBLIC_ENDPOINT)
        }else{
          this.setEndpoint(process.env.VUE_APP_EPHEMERAL_ENDPOINT)
        }
        console.log(`Origin: ${origin} | Endpoint Set: ${this.getEndpoint}`)

        /*
          envConstants are used with semi-dynamic 
          values for certain application variables, where 
          they are required before the user is authenticated 
          and can request the GLOBALS from db.

        */
        let envConstants = {
          ADMIN_TITLE: process.env.VUE_APP_ADMIN_TITLE,
          ADMIN_LOGIN_PAGE_TITLE: process.env.VUE_APP_ADMIN_LOGIN_PAGE_TITLE,
        }

        document.querySelector('title').innerHTML = envConstants.ADMIN_TITLE
        this.setEnvConstants(envConstants)
        
        if(this.isLoggedIn){
          this.setGLOBALS((await axios.get(`${this.getEndpoint}/api/globals`)).data.data)
          let branches = await axios.get(`${this.getEndpoint}/api/locations/branches`)
          if(branches.data.error) console.log("APP.JS❌ Location Data Not Fetched.")
          else this.setBranches(branches.data.data);

          let locations = await axios.get(`${this.getEndpoint}/api/locations`)
          if(locations.data.error) console.log("APP.JS❌ Location Data Not Fetched.")
          else this.setLocations(locations.data.data);

          if(!this.getUser){
            let user = await axios.get(`${this.getEndpoint}/api/users/${this.getId}`)
            if(!user.data.error) this.setUser(user.data.data)
          }
          
          if(this.paymentMethods.length<1){
            let paymentMethods = await axios.get(`${this.getEndpoint}/api/paymentmethods/lite`)
            if(!paymentMethods.data.error) this.setPaymentMethods(paymentMethods.data.data)
          }

          if(this.paymentTypes.length<1){
            let paymentTypes = await axios.get(`${this.getEndpoint}/api/paymenttypes/lite`)
            if(!paymentTypes.data.error) this.setPaymentTypes(paymentTypes.data.data)
          }

          if(this.getPerms==null){
            let perms = await axios.get(`${this.getEndpoint}/getPerms`)
            if(!perms.data.error) this.setPerms(perms.data)
          }

          this.setManaged(!!this.getBranchData(this.getBranch)?.metadata.isManaged)

          let userNames = await axios.get(`${this.getEndpoint}/api/users/userNames`)
          if(userNames.data.error) console.log("APP.JS❌ User Cache Data Not Fetched.")
          else this.setUserCache(userNames.data.data)

          //Barcode Daemon
          //Barcode and Shortcut Daemon
          this.activateScanSense()
          }
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
        console.error("Could not load user permissions. Please refresh the page.")
      } finally {
        this.ready = true;
      }
    },
    computed: {
      ...mapGetters([
        'isLoggedIn',
        'getPerms',
        'globalQueryBcAllowed',
        'getEndpoint',
        'isAllowed',
        'getUser',
        'getId',
        'scanBus',
        'lookupUsername',
        'lookupBranch',
        'getAllowedBranches',
        'getBranch',
        'isManaged',
        'menuItems',
        'getBranchData',
        'paymentTypes',
        'paymentMethods',
        'activateScanSensePing',
        'getGlobalValue',
      ]),
    },
    methods: {
      ...mapMutations([
        'logout',
        'setPerms',
        'setUser',
        'setEndpoint',
        'setUserCache',
        'lockGlobalQueryBc',
        'unlockGlobalQueryBc',
        'setScanBus',
        'resetScanBus',
        'setBranches',
        'setManaged',
        'setPaymentTypes',
        'setPaymentMethods',
        'setGLOBALS',
        'setLocations',
        'setEnvConstants'
      ]),
      logoutA(){
        this.logoutDialog = true
      },
      async logoutB(){
        console.log("Logout")
        this.logoutDialog = false
        await this.$router.push({path: "/logout"})
      },
      activateScanSense(){
        console.log('APP.JS: ✅ Scan Sense Active.')
        window.addEventListener('keydown', this.keydown)
      },
      keydown(e){
        const CHORDKEY = '/'
          //formulates a scanstring and passes it to the scanbus
          if(this.getUser){ //only active when user is logged in

            //console.log('APP', e)
            this.cancelClearScanStringTimeout()
            this.clearScanStringTimeout()

            if(this.chordingDialog.isOpen){ // if we are in chording mode, suspend scanning operations until exited

              this.cancelClearScanStringTimeout()

              if(e.key===CHORDKEY){
                this.endShortcut()
              }else{
                this.handleChord(e)
              }

            }else{ //user is not in chording mode (could either be typing or scanning)
              
              if(e.key==="Enter"){ //if keypress is enter
                //determine if scanString is a barcode
                let isBarcode = false

                if(this.scanString?.length>5){  //a barcode will at least have a 5 char signature \//\= and at least 1 character. if this is not met, we can assume its not valid
                  isBarcode = scanSystem.validateBarcode(this.scanString)
                }
                
                if(isBarcode){ // if it is a barcode 
                  
                  // push to scanbus 
                  this.setScanBus({data: this.scanString})
                }

              }else{ // keypress is not enter
                  
                if(e.key===CHORDKEY && this.scanString.length == 0){ // if  keyPress is / and the current scanString is empty ie a bc is not being streamed in
                  
                  //open chording Dialog
                  this.cancelClearScanStringTimeout()
                  this.chordingDialog.isOpen = true 
                  //set scanbus for global chording
                  this.setScanBus({handler:"globalChord", data: ""})

                }else if(e.key!='Shift' && e.key!='LeftAlt'){ //if keypress is not the CHORDKEY while scanstring is empty and is not SHIFT OR ALT

                  // continue building scanstring
                  if(!this.scanString || this.scanString === "" || this.scanString === undefined || this.scanString === null) // if this is excluded, the barcode will always have undefined as the first character
                    this.scanString = e.key
                  else this.scanString += e.key

                }
              }
            }
          }
      },
      async openProductDialog(id){
        try {
          this.productDialog.isOpen = true
          this.productDialog.isLoading = true 
          this.productDialog.data = await this.lookupProduct(id)
          if(!this.productDialog.data) throw "External Product Not In VIMS DB."
          this.productDialog.isLoading = false
          this.resetScanBus() 
        } catch (error) {
          console.log(error)
          console.log("here")
          this.snack(error)
          this.closeProductDialog()
        }
      },
      closeProductDialog(){
        this.productDialog = {
          isOpen: false,
          isLoading: false,
          data: {}
        }
      },
      getGreeting(){
        if(this.getUser != null && this.getUser != undefined){
          let d = new Date().getHours()
          let h = ''
          if(d >= 0 && d < 11) h = 'Morning'
          if(d >= 11 && d < 14) h = 'Day'
          if(d >= 14 && d < 17) h = 'Afternoon'
          if(d >= 17 && d < 19) h = 'Evening'
          if(d >= 19 && d <= 23) h = 'Night'
          return 'Good ' + h + ', ' + this.getUser.firstName+"!"
        }
      },
      clearScanString(){
        this.scanString = ''
        console.log("🔁 Scan String Cleared")
      },
      clearScanStringTimeout(){
        this.timeout = setTimeout(this.clearScanString,500)
      },
      cancelClearScanStringTimeout(){
        clearTimeout(this.timeout)
      },
      async globalHandler(){
        try {
          console.log("Global Handler Fired")

          let type = scanSystem.validateType(this.scanBus.data)
          console.log(type)
          let s = this.scanBus.data.replace(/\\\/\/\\=/,'')
          let pId = (s.replace(`${type}-`, ''));
          let p, cn;
          switch(type){
            case 'INV':
              console.log("Invoice Scanned: ", s)
              p = `/orders/view/${s.split('-')[1]}`
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              this.scanInProgress = false
              break
            case 'VSID':
              // this.setScanBus({username: this.lookupUsername(pId.split("-")[0]), uId: pId.split("-")[0]})
              // console.log(this.lookupUsername(pId.split("-")[0]))
              p = '/users/view/'+pId.split("-")[0]
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              this.scanInProgress = false
              break
            case 'JT':
              console.log("Job Ticket Scanned: ", s)
              p = `/orders/view/${s.split('-')[1]}`
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              this.scanInProgress = false
              break
            case 'DN':
              console.log("Delivery Note Scanned: ", s)
              p = `/deliveries/view/${s.split('-')[1]}`
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              this.scanInProgress = false
              break
            case 'PL':
              console.log("Product Scanned: ", s)
              p = `/products/view/${s.split('-')[1]}`
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              this.scanInProgress = false
              break
            case 'CN':
              console.log("Credit Note Scanned: ", s)
              cn = await axios.get(`${this.getEndpoint}/api/creditNotes/${s.split('-',)[1]}`)
              if(cn.data.error) throw cn.data.error
              p = `/customers/view/${cn.data.data.customerId}?cn=${cn.data.data.id}`
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              this.scanInProgress = false
              break
            case 'SKU':
              console.log("External Product Scanned: ", s)
              this.openProductDialog(s)
              // p = `/products/view/${prod.id}`
              // if(this.$route.path!==p)
              //   await this.$router.push({path: p})
              // this.scanInProgress = false
              break
          }
        } catch (error) {
          console.log(error)
          this.scanInProgress = false
          this.snack(error)
        }
      },
      endShortcut(){
        this.clearScanString()
        this.chordingDialog.chord = []
        this.chordingDialog.isOpen = false
        this.resetScanBus()
      },
      async handleChord(code){
        try {
          this.search.results = []
          let key = code.key.toLowerCase()
          this.chordingDialog.chord.push(key)
          if(this.chordingDialog.chord[this.chordingDialog.chord.length-1]==='x'){
            this.chordingDialog.chord = []
            this.search.results= []
          }
          if(this.chordingDialog.chord[this.chordingDialog.chord.length-1]==='backspace'){
            this.chordingDialog.chord.pop()
            this.chordingDialog.chord.pop()
          }
          let str = this.chordingDialog.chord.join().replace(",","")
          if(str =='on' || str =='n' ){
            console.log("Create New Order")
            this.attemptCreateInvoice()
            this.endShortcut()         
          }
          if(str ==='ol'){
            console.log("List Orders")
            await this.$router.push({path: `/orders`})
            this.endShortcut()              
          }
          if(str[0]=='o'&&str[1]=='v'&&str.length>2&&this.chordingDialog.chord[this.chordingDialog.chord.length-1] == 'enter'){
            let orderId = this.chordingDialog.chord.slice(2,this.chordingDialog.chord.length-1)
            orderId = orderId.join("").replace(/,/g,"")
            console.log(orderId)
            await this.$router.push({path: `/orders/view/${orderId}`})
            this.endShortcut()
          }
          if(str ==='h'){
            await this.$router.push({path: `/`})
            this.endShortcut()              
          }
          if(str[0]=='s'&&str[1]=='p'&&str.length>2){
            let prod = this.chordingDialog.chord.slice(2)
            prod = prod.join("").replace(/,/g,"")
            this.search.results = []
            this.search.type = 'Products'
            this.search.value = prod
            this.querySearch()
          }
          if(str[0]=='s'&&str[1]=='c'&&str.length>2){
            let prod = this.chordingDialog.chord.slice(2)
            prod = prod.join("").replace(/,/g,"")
            this.search.results = []
            this.search.type = 'Customers'
            this.search.value = prod
            this.querySearch()
          }
        } catch (error) {
          console.log(error)
          this.snack(error)
        }
      },
      async attemptCreateInvoice(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/orders`, {createdBy: this.getId})
          if(res.data.error) throw res.data.error
          console.log(res.data)
          await this.$router.push({path: `/orders/view/${res.data.data.id}`})
        } catch (error) {
          console.log(error)
        }
      },
      async updateBranch(data){
        try{
          this.branchLoader = true;
          let res = await axios.put(`${this.getEndpoint}/api/users/updateMainBranch/${this.getId}`, {branch: data})
          if(res.data.error) throw res.data.error

          await this.setUser(res.data.data)
          this.setManaged(!!this.getBranchData(this.getBranch)?.metadata.isManaged)
          this.snack("Location Updated");
          if(this.$route.path!=="/") await this.$router.push({path: `/`})

        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
        finally {
          this.branchLoader = false;
        }
      },
      async lookupProduct(s){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/products/bySKU/${s}`)
          if(res.data.error) throw res.data.error
          if(!res.data.data) throw 'External barcode is not in VIMS DB.'
          return res.data.data
        } catch (error) {
          if(error.customError){
            console.error(error)
            this.snack('External barcode is not in VIMS DB.')
          }
          else this.snack(error)
        }
      },
      snack(text, color=""){
        if(!text.includes('NavigationDuplicated')){
          this.snackObj.text = text;
          this.snackObj.state = true;
          this.snackObj.color = color;
        }
      },
      openSearchDialog(){
        this.search.dialog=true;
      },
      clearSearch(){
        this.search.value = "";
        this.search.results = [];
      },
      async querySearch(){
        try{
          this.search.loading = true;
          this.search.results = [];

          if(!this.search.value) return;

          let searchData = {
            val: this.search.value
          }

          let uriFields = Object.keys(searchData).map(x => {
            return x + "=" + (searchData[x]!==undefined?encodeURIComponent(searchData[x]):'')
          }).join("&");

          let res;
          let arr = [];
          switch (this.search.type) {
            case "Customers":
              res = await axios.get(`${this.getEndpoint}/api/customers/search?${uriFields}`);
              if(res.data.error) throw res.data.error;
              arr = res.data.data;
              break;
            case "Products":
            default:
              res = await axios.get(`${this.getEndpoint}/api/products/searchByAllVariationsNoLimit?${uriFields}`);
              if(res.data.error) throw res.data.error
              arr = res.data.data.concat(res.data.brandSearch);
          }

          this.search.results = arr;
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
        finally {
          this.search.loading = false;
        }
      },
      async goToProduct(prod){
        this.closeProductDialog()
        await this.$router.push({path: `/products/view/${prod.id}`})

      } 
    }
  }
</script>